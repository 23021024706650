import React from "react";
import { environment, project } from "./environment";
import Nav from "./Nav.js";
import NavSide from "./NavSide.js";
import Image from "./components/Image.js";
import Svg from "./components/Svg";
import useWindowDimensions from "./Device.js";

function Header(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Header');
    console.log(params)
  }
  const { device } = useWindowDimensions();
  function OpenMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
      CloseSide()
    }
    else if (!sidemenu.classList.contains('open')) {
      sidemenu.classList.add("open");
    }
  };
  function CloseMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
      CloseSide()
    }
  };
  function CloseSide() {
    const sidenav = document.getElementById("sidenav");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
  };
  if (device !== 'desktop') {
    return (
      <div id="sidemenu">
        <div className="topbar fix-menu b-white">
          <div className="container">
            <div className="topbar-container d-flex jc-between f-wrap">
              <a className="logo-link no-anim w-100 ta-center" href={environment.mainPage}>
                <Image src={environment.logoMain} alt={project.fullname} />
              </a>
              <div className="bott-menu d-flex ai-center jc-between w-100">
                <div className="menu-button">
                  <div id="nav-icon" className="nav-icon" onClick={(e) => {e.preventDefault();OpenMenu();}}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="log-button d-flex ai-center jc-between">
                  <a href="https://www.facebook.com/bankrumia" target="_blank" rel="noreferrer" title="Odwiedź nas na Facebook" className="hover-fb">
                    <Svg name="svgFBHeader" />
                  </a>
                  <a href="https://www.linkedin.com/company/bank-rumia/?viewAsMember=true" target="_blank" rel="noreferrer" title="Odwiedź nas na LinkedIn" className="hover-fb">
                    <Svg name="svgLinkedin" />
                  </a>
                  <a href="https://www.instagram.com/bankrumia/" target="_blank" rel="noreferrer" title="Odwiedź nas na Instagram" className="hover-fb">
                    <Svg name="svgInstagram" />
                  </a>
                  <a href={environment.searchPage} className="menu-text" id="search">
                    <Svg name="SearchIcon" class="menu-icon"/>
                  </a>
                  <a href="https://sgb24.pl/" target="_blank" title="login" className="button primary">
                    SGB24
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar hovered-menu">
          <NavSide line={params.line} />
        </div>
        <div className="menu-overlay" onClick={(e) => {e.preventDefault();CloseMenu();}}></div>
      </div>
    )
  }
  else {
    return (
			<div id="nav" className="desktop">
				<div className="box-menu b-white">
          <div className="container pt-0 pb-0 d-flex ai-center jc-between">
            <div className="logo-menu d-flex ai-center">
              <a className="mr-25 no-anim" href="/">
                <Image src={environment.logoMain} alt={project.fullname} />
              </a>
            </div>
            <nav className="main-nav d-flex jc-between ai-center">
              <Nav line={params.line} type="header"/>
              <a href="https://sgb24.pl/" target="_blank" title="login" className="button primary">
                SGB24
              </a>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;